import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import './HomePageCard.css'
import DynamicSvg from '../../DynamicSvg';

const HomePageCardComponent = (props) => {
    const {
        cardComponentId='',
        hideLastElement=false
    } = props 
    const[mouseHover, setMouseHover] = useState("");
       
    return (
        <div className="home-page-card-outer-div" id={cardComponentId}>
            {
                props.cardDataList.map((data, index) => {
                    const HeadingTag = data.headingTag
                    const imageFolder = data?.icon?.split(".")[1] === "png" ? "images" : "svgs";
                    const imageSourecAttObj = {
                        [props?.withOutLazyLoading ? 'srcSet' : 'data-srcset']: `/assets/webpImages/${data.webpIcon}`
                    }
                    const imageAttObj = {
                        'src': `/assets/${imageFolder}/${mouseHover === data.id ? data.iconOnHover : data.icon}`,
                        width: data.width ? data.width : "44",
                        height: data.height ? data.height :"44",
                        alt: data.alt ? data.alt : data.cardHeading,
                        className:`${props?.withOutLazyLoading ? "" : 'lazyload'} ${mouseHover === data.id ? "d-none" : ''}`
                    }
                    const handleMouseOver = (cardId) => {
                        setMouseHover(cardId);
                    }
                    return (
                        <React.Fragment key={`card-comp-${index}`}>
                            {hideLastElement && index === props.cardDataList.length-1 ? null :
                                <React.Fragment key={`card-comp-sub-fragment-${index}`}>
                                    {data.link ? <div className="home-page-card-feature-container-section" key={`homepage_card_${index}`}>
                                        <Link to={`/${data.link}`} onClick={
                                            () => props.onButtonClick(data.eventType)}>
                                            <div className="home-page-card-feature-section" onMouseOver={()=>handleMouseOver(data.id)} onMouseOut={() => setMouseHover("")}>
                                                {
                                                    data.icon !== 'false' &&
                                                    <div className="home-page-card-icon-style">
                                                        {data?.iconOnHover && <img src={`/assets/${imageFolder}/${data.iconOnHover}`}
                                                            width={data.width ? data.width : "44"}
                                                            height={data.height ? data.height : "44"}
                                                            alt={data.alt ? data.alt : data.cardHeading}
                                                            className={`${props?.withOutLazyLoading ? "" : 'lazyload'} ${mouseHover !== data.id ? "d-none" : ''}`}>
                                                        </img>}
                                                        <DynamicSvg {...imageAttObj} />
                                                    </div>
                                                }
                                                <div className="home-page-card-heading" pomandae2e={data.cardHeading}>{data.cardHeading}</div>
                                                <div className="home-page-card-text" pomandae2e={data.cardText}>{data.cardText}</div>
                                                <div className="home-page-card-button" pomandae2e={data.button}> {data.button} </div></div></Link></div>
                                        : <div className="home-page-card-feature-section" key={`homepage_card_${index}`}>
                                            {
                                                data.icon !== 'false' &&
                                                <div className="home-page-card-icon-style">
                                                    {data.iconOnHover && <img src={`/assets/${imageFolder}/${data.iconOnHover}`}
                                                        width={data.width ? data.width : "44"}
                                                        height={data.height ? data.height : "44"}
                                                        alt={data.alt ? data.alt : data.cardHeading}
                                                        className={`${props?.withOutLazyLoading ? "" : 'lazyload'} ${mouseHover !== data.id ? "d-none" : ''}`} />}
                                                        <DynamicSvg {...imageAttObj} />
                                                    {props?.businessValueSteps && <MediaQuery maxWidth={575.78}>
                                                        <span className='business-value-steps-ppc'>{index + 1}</span>
                                                    </MediaQuery>}
                                                </div>
                                            }
                                            {data?.headingTag ? <HeadingTag className="home-page-card-heading" pomandae2e={data.cardHeading}>{data.cardHeading}</HeadingTag>
                                                : <div className="home-page-card-heading">{data.cardHeading}</div>}
                                            <div className="home-page-card-text">{data.cardText}</div>

                                            <div className="home-page-card-button"> {data.button} </div>

                                        </div>}
                                </React.Fragment>}
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
}

export default HomePageCardComponent;