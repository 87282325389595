import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const DynamicSvg = (props) => {
  const [modifiedSvg, setModifiedSvg] = useState(null);
  const secondaryColour = useSelector(state=> state.common.whiteLabelConfig?.colourScheme?.secondaryColour)

  useEffect(() => {
    if(secondaryColour){
    fetch(props?.src)
      .then((response) => response.text())
      .then((svgContent) => {
        svgContent = svgContent.toString().replace(/#E1A132/g,secondaryColour )
        const imgSrcStart = `data:image/svg+xml;base64,`;
         const modifiedSvgContent = imgSrcStart.concat(new Buffer.from(svgContent).toString('base64'));
        setModifiedSvg(modifiedSvgContent);
      })
    }
  }, [secondaryColour,props?.src]);
  return modifiedSvg ? <img {...props} src={modifiedSvg} /> : <img {...props} />;
};

export default DynamicSvg;
