import React, { useState } from 'react';
import YouTubePlayVideoModal from './YouTubePlayVideoModal'
import { ET_YOUTUBE_VIDEO_CLOSED, ET_YOUTUBE_LINK_CLICKED } from '../../common/trackingConstants'

import "./YouTubeCard.css"

const YouTubeCard = (props) => {
    const [play, setPlay] = useState(false);
    let onCardClick = () => {
        const { youtubeCardData } = props
        youtubeCardData.callActivityTracking({
            eventType: !play ? ET_YOUTUBE_LINK_CLICKED : ET_YOUTUBE_VIDEO_CLOSED
        })
        setPlay(!play)

    }
    return (
        <>
            <div className="youtube-card" >
                <div className="image-div" pomandae2e="powersearch youtube video" onClick={() => onCardClick()}>
                    <picture >
                        <source type="image/webp" srcSet="/assets/webpImages/yb4.webp" />
                        <img className="youtube-image lazyload" src="/assets/images/yb4.png" alt="power search youtube video link" width="100%" height="100%"/>
                    </picture>
                </div>
                <div className="youtube-content-div">
                    <p className="youtube-card-heading">How to use the Power Search</p>
                    <p className="youtube-card-desc">Watch this video to learn how to use the power search. </p>
                    <p className="youtube-card-play-now" onClick={() => onCardClick()}>Play Now</p>
                </div>
            </div>
            <YouTubePlayVideoModal
                play={play}
                onCardClick={onCardClick}
            />
        </>
    )
};

export default YouTubeCard;