import React, { memo, useState } from 'react';
import MediaQuery from 'react-responsive';
import FeatureDetailsComponent from '../LeftSideImageRightSideText/FeatureDetailsComponent/FeatureDetailsComponent';
import './RightSideImageLeftSideText.css';

const CustomMediaQuery = ({ children, mediaQueryParamImg = "" }) => {
    let renderedUi = null;
    if (mediaQueryParamImg === "desktop") {
        renderedUi = <MediaQuery minWidth={992}>{children}</MediaQuery>
    } else {
        renderedUi = <>{children}</>
    }
    return renderedUi;
}

const RightSideImageLeftSideText = memo(function RightSideImageLeftSideText(props) {
    const {
        componentName = "",
        imageUrl = "",
        webPimageUrl = "",
        imageUrlMobileView = "",
        featureDetailsArr = [],
        imageWidth = "",
        imageHeight = "",
        altTag = "",
        mediaQueryParamImg = "",
        videoClipObj = "",
        HeadingTag = "h2",
        supportImage = {},
        isShowMoreFunc = {},
        logoPath = ""
    } = props;
    const getAltAttribute = (imagePath) => {
        if (!imagePath) {
            return "";
        }
        const imageName = imagePath.split("/");
        const altTagAttribute = imageName[imageName.length - 1].split(".")[0];
        return `${altTagAttribute}_image`;
    }
    return (
        <div className="right-side-img-left-side-text">
            <FeatureDetailsComponent dataArr={featureDetailsArr} componentName={componentName} HeadingTag={HeadingTag} logoPath={logoPath} isShowMoreFunc={isShowMoreFunc} />
            <CustomMediaQuery mediaQueryParamImg={mediaQueryParamImg}><div className="right-side-image-parent">
                {supportImage?.imageUrl && <img
                    src={supportImage.imageUrl}
                    width={supportImage.width}
                    height={supportImage.height}
                    alt='support_img'
                    className='right-side-support-image'
                />}
                {imageUrlMobileView && <picture>
                    <img data-src={imageUrlMobileView}
                        className={`right-side-image lazyload`}
                        width={imageWidth ? imageWidth : "541"}
                        height={imageHeight ? imageHeight : "425"}
                        alt={altTag ? altTag : getAltAttribute(imageUrlMobileView)}
                    />
                </picture>}

                {(imageUrl || webPimageUrl) && <picture>
                    <source type="image/webp" data-srcset={webPimageUrl} />
                    <img data-src={imageUrl} className="right-side-image lazyload" width={imageWidth ? imageWidth : "541"}
                        height={imageHeight ? imageHeight : "425"} alt={altTag ? altTag : getAltAttribute(imageUrl)}>
                    </img>
                </picture>}
                {videoClipObj?.videoClipSrc ? <video
                    src={videoClipObj.videoClipSrc}
                    width={videoClipObj.width} height={videoClipObj.height} autoPlay loop muted className='right-side-video lazyload'>
                </video> : ""}
            </div></CustomMediaQuery>
        </div >
    );
});

export default RightSideImageLeftSideText;