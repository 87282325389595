import React from 'react';

import { Modal, ModalBody } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import {isBrowser, POWER_SEARCH_YOUTUBE_VIDEO_ID} from '../../common/config'
// import YouTube from 'react-youtube';

const YouTubePlayVideoModal = (props) => {
    const {
        modalClassName = "",
        headerText = "How to use the Power Search",
        centered = false,
        inLineStyle = {},
        videoAddress = ""
    } = props;
    const origin=isBrowser && window.location.origin
    const videoUrl= videoAddress || `https://www.youtube.com/embed/${POWER_SEARCH_YOUTUBE_VIDEO_ID}?autoplay=1&origin=${origin}`
 
    return (
        <Modal id="YouTubePlayVideoModal" className={modalClassName ? modalClassName : ""} centered={centered} toggle={props.onCardClick} isOpen={props.play}>
            <ModalHeader toggle={props.onCardClick}>{headerText}</ModalHeader>
            <ModalBody  >
                <iframe id="pomandaYoutubeId" width="100%" height="100%" allowFullScreen="1" style={inLineStyle}
                frameBorder="0" src={videoUrl}>
                </iframe>

            </ModalBody>
        </Modal>

    );
};

export default YouTubePlayVideoModal;